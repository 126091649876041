export const getPageDetails = (applicationName, pathname) => {
  const pageName = getPageName(pathname || '');

  if (!pageName) {
    return null;
  }

  return { applicationName, pageName, pathname };
};

const getPageName = pathname => {
  if (isProfilePage(pathname)) {
    return 'Profile Page';
  }
  return null;
};

const isProfilePage = pathname => {
  return pathname.includes('/profile/');
};
