import { useEffect, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { get } from 'lodash';

import { getConfig } from '../config/get-config';

const { PENDO_API_KEY } = getConfig();

export const CERTIFICATION_FIELDS = gql`
  fragment Certifications on Profile {
    certifications {
      id
      title
      imageUrl
      acquiredDate
      expiryDate
      credentialUrl
      certificateNumber
      certification {
        id
        title
        provider {
          id
          title
        }
      }
    }
  }
`;

export const EMPLOYMENT_HISTORY_FIELDS = gql`
  fragment EmploymentHistories on Profile {
    employmentHistories {
      id
      title
      company
      description
      startDate
      endDate
    }
  }
`;

export const VISIBILITY_TOGGLES_FIELDS = gql`
  fragment VisibilityToggles on Profile {
    visibilityToggles {
      cloudTranscripts
      certifications
      employmentHistories
    }
  }
`;

export const COMPLETED_COURSES_FIELDS = gql`
  fragment CompletedCourses on Profile {
    completedCourses {
      id
      artworkUrl
      name
    }
  }
`;

export const PROFILE_EMPLOYMENT_HISTORIES = gql`
  ${EMPLOYMENT_HISTORY_FIELDS}
  query ProfileByHandle($input: ProfileByHandleInput!) {
    profileByHandle(input: $input) {
      id
      ...EmploymentHistories
    }
  }
`;

export const PROFILE_CERTIFICATIONS = gql`
  ${CERTIFICATION_FIELDS}
  query ProfileByHandle($input: ProfileByHandleInput!) {
    profileByHandle(input: $input) {
      id
      ...Certifications
    }
  }
`;

export const PROFILE_COMPLETED_COURSES = gql`
  ${COMPLETED_COURSES_FIELDS}
  query ProfileByHandle($input: ProfileByHandleInput!) {
    profileByHandle(input: $input) {
      id
      ...CompletedCourses
    }
  }
`;

export const IS_HANDLE_TAKEN_QUERY = gql`
  query IsHandleTaken($handle: String!) {
    profileByHandle(input: { handle: $handle }) {
      handle
    }
  }
`;

export const PROFILE_BY_HANDLE_QUERY_ONLY_REDIRECTION_RULES = gql`
  query ProfileByHandle($input: ProfileByHandleInput!) {
    profileByHandle(input: $input) {
      id
      redirectionRules {
        rule
        targetUsername
      }
    }
  }
`;

export const PROFILE_BY_HANDLE_QUERY = gql`
  ${CERTIFICATION_FIELDS}
  ${EMPLOYMENT_HISTORY_FIELDS}
  ${COMPLETED_COURSES_FIELDS}
  ${VISIBILITY_TOGGLES_FIELDS}
  query ProfileByHandle($input: ProfileByHandleInput!) {
    userIdentity {
      id
      isMigrated
    }

    profileByHandle(input: $input) {
      id
      name
      displayname
      email
      jobTitle
      location
      employer
      picture
      bio
      linkedinUrl
      facebookUrl
      githubUrl
      twitterUrl
      handle

      ...EmploymentHistories

      communityEngagement {
        questions
        answers
        reputation
      }

      restrictions {
        restrictProfileEdit
      }

      redirectionRules {
        rule
        targetUsername
      }

      ...VisibilityToggles

      ...Certifications

      ...CompletedCourses
    }
  }
`;

export const useHandleAvailability = () => {
  const [query, { loading, data, error, called, variables }] = useLazyQuery(
    IS_HANDLE_TAKEN_QUERY
  );

  const cache = useMemo(Object, []);
  if (data) cache[variables.handle] = data.profileByHandle === null;

  return [
    useCallback(handle => {
      if (!(handle in cache)) query({ variables: { handle } });
    }, []),
    {
      called,
      loading,
      error,
      availability: cache
    }
  ];
};

export const useProfileByHandle = handle => {
  const { loading, error, data } = useQuery(PROFILE_BY_HANDLE_QUERY, {
    variables: { input: { handle } }
  });

  const profile =
    !loading && data
      ? {
          ...data.profileByHandle,
          isMigrated: get(data, 'userIdentity.isMigrated', false)
        }
      : {};

  useEffect(() => {
    if (!loading && data && window && window.pendo && window.pendo.identify) {
      const { profileByHandle, userIdentity } = data;
      window.pendo.apiKey = PENDO_API_KEY;

      window.pendo.identify({
        visitor: {
          id: profileByHandle.id,
          full_name: profileByHandle.name,
          location: profileByHandle.location,
          email: profileByHandle.email,
          isMigrated: get(userIdentity, 'isMigrated', false)
        }
      });
    }
  }, [data, loading]);

  return { loadingProfile: loading, error, profile };
};
