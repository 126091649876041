module.exports = {
  ACG_DOMAIN: 'https://acloud.guru',
  ACG_TEAMS_DOMAIN: 'http://teams.acloud.guru',
  ACG_SCHOOL_DOMAIN: 'https://learn.acloud.guru',
  ACG_MEMBERSHIP_DOMAIN: 'https://checkout.acloud.guru/signup',
  ACG_VERIFY_DOMAIN: 'https://verify.acloud.guru',
  ACG_INTERNAL_PROFILE_DOMAIN:
    'https://production-internal-profile.acloud.guru',
  ALGOLIA_PUBLIC_SEARCH_KEY: 'bd07683ba0f90f58b2ea5def1ec0cc27',
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  ALGOLIA_COMPANY_NAME_INDEX_NAME: 'prod_companies',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'OKFg9uoPMCNelvlvKVDfPK766DRdAYD5',
  AUTH0_ROLES_NAMESPACE: 'https://ns.acloud.guru/roles',
  BUGSNAG_API_KEY: 'be470a55cee15da1796290e0fc6827f3',
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  DEFAULT_REDIRECT_URI: 'https://learn.acloud.guru/profile/login/callback',
  GOOGLE_PLACES_API_KEY: 'AIzaSyBqtuoFSFt4uN8kEhiZqXW5gp0-NS9mfy4',
  GRAPHQL_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql/',
  PENDO_API_KEY: 'a006fa43-8a84-427f-4cf3-6e601ba0fe76',
  PLACES_APP_ID: 'plGT9D0FCXMK',
  PLACES_SEARCH_KEY: '7e9952c20ba1ddbeed024611c93fe960',
  PROFILE_NO_IMAGE: 'https://public.acloud.guru/assets/profile-no-image.png',
  SSR_ENABLED: true,
  SPLIT_IO_API_KEY: 'nioe9lc520jcf370njbhbcq55gpr88qijg4c',
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '5a7c7b5afd95332db01686a1',
  PLURALSIGHT_SKILLS_PROFILE_BASE_URL: 'https://app.pluralsight.com/profile/',
  SUNSET_PROFILE_REDIRECTION: true,
  CLOUD_PROFILE_EXPERIENCE_ACTIVE: true,
  PLURALSIGHT_OIDC_URL: 'https://app.pluralsight.com/id/signin/sso?',
  PLURALSIGHT_OIDC_ALIAS: 'Alias=Acg-oidc',
  PS_LOGOUT_URL: 'http://app.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'https://acloud.guru',
    'https://*.acloud.guru',
    'https://acloudguru.firebaseapp.com',
    'https://mr-magoriums-wonder-emporium.netlify.app',
    'https://*.linuxacademy.com',
    'https://acg',
    'https://*.auth0.com',
    'https://*.pluralsight.com'
  ]
};
