import fromEvent from 'xstream/extra/fromEvent';

const subscribeToEvents = (
  onEvent,
  trackedElements,
  getPageDetails,
  eventSubscriptions
) => {
  function doNext(e) {
    onEvent(e, trackedElements, getPageDetails);
  }

  return () => {
    // eslint-disable-next-line no-param-reassign
    eventSubscriptions.click = fromEvent(document, 'click').subscribe({
      next: e => doNext(e)
    });
  };
};

export default subscribeToEvents;
