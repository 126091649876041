const getConfig = env => {
  let environment = env || process.env.ACG_ENV;
  if (process.env.NODE_ENV === 'test' && !environment) {
    environment = 'test';
  }

  try {
    // eslint-disable-next-line
    return require(`./${environment}.config.js`);
  } catch (err) {
    throw new Error(`Could not find config for ${environment}`);
  }
};

module.exports = {
  getConfig: () => ({
    ...getConfig(process.env.ACG_ENV),
    ACG_ENV: process.env.ACG_ENV
  })
};
