import React from 'react';
import PropTypes from 'prop-types';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'cookies';

import { bugsnagClient } from '../../clients/bugsnag';
import { PROFILE_BY_HANDLE_QUERY_ONLY_REDIRECTION_RULES } from '../../hooks/useProfile';

const isServer = typeof window === 'undefined';

let cachedClient;

const getDisplayName = PageComponent => {
  const displayName =
    PageComponent.displayName || PageComponent.name || 'Component';

  if (displayName === 'App') {
    console.warn('This withApollo HOC only works with PageComponents.');
  }

  return `withApollo(${displayName})`;
};

const getWithApollo = createApolloClient => (
  PageComponent,
  { ssr = true } = {}
) => {
  const initApolloClient = (...args) => {
    if (isServer) {
      return createApolloClient(...args);
    }

    if (!cachedClient) {
      cachedClient = createApolloClient(...args);
    }

    return cachedClient;
  };

  const WithApollo = ({ apolloClient, apolloState, ...pageProps }) => {
    if (pageProps.isProfileNotFound) {
      const err = new Error();
      err.code = 'ENOENT';
      throw err;
    }

    const client =
      apolloClient ||
      initApolloClient({ initialState: apolloState, headers: {} });

    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  WithApollo.displayName = getDisplayName(PageComponent);
  WithApollo.propTypes = {
    apolloClient: PropTypes.object,
    apolloState: PropTypes.object
  };
  if (!ssr) {
    return WithApollo;
  }

  WithApollo.getInitialProps = async ctx => {
    const { AppTree } = ctx;
    const cookies = new Cookies(ctx.req, ctx.res);

    const apolloClient = initApolloClient({
      initialState: {},
      headers: ctx.req?.headers
    });
    ctx.apolloClient = apolloClient;

    const pageProps = PageComponent.getInitialProps
      ? await PageComponent.getInitialProps(ctx)
      : {};

    if (isServer) {
      if (ctx.res && ctx.res.finished) {
        return {};
      }

      try {
        console.log('SSR: ready to getDataFromTree');
        await getDataFromTree(
          <AppTree
            pageProps={{
              ...pageProps,
              apolloClient
            }}
          />
        );

        console.log(
          'ctx.query.handle && process.env.SUNSET_PROFILE_REDIRECTION',
          ctx.query.handle,
          process.env.SUNSET_PROFILE_REDIRECTION
        );
        if (ctx.query.handle && process.env.SUNSET_PROFILE_REDIRECTION) {
          const handleForQuery = `${ctx.query.handle}`.toLowerCase();
          const cachedProfile = apolloClient.readQuery({
            query: PROFILE_BY_HANDLE_QUERY_ONLY_REDIRECTION_RULES,
            variables: {
              input: { handle: handleForQuery }
            }
          });
          if (
            !cachedProfile ||
            !cachedProfile.profileByHandle ||
            !cachedProfile.profileByHandle.redirectionRules
          ) {
            ctx.res.statusCode = 404;
            console.log(
              'SSR: Profile not found, user redirected to 404 error page'
            );
            return { isProfileNotFound: true };
          }
          const {
            rule: redirectionRule,
            targetUsername: psUsername
          } = cachedProfile.profileByHandle.redirectionRules;
          const isUserAnonymousVisitor = !cookies.get('auth0_token');

          console.log('redirectionRules determined', {
            redirectionRule,
            psUsername
          });

          switch (redirectionRule) {
            case 'STAY_ACG':
              break;
            case 'BLOCKLIST_KB_ARTICLE':
              ctx.res.writeHead(301, {
                Location: 'https://help.pluralsight.com/help/profile-error'
              });
              ctx.res.end();
              console.log(
                `SSR: user with handle '${ctx.query.handle}' redirected to KB article for Blocklist users.`
              );
              break;
            case 'SAVEANDPROTECT_KB_ARTICLE':
              ctx.res.writeHead(301, {
                Location: 'https://help.pluralsight.com/help/profile-error' // This will change in the future
              });
              ctx.res.end();
              console.log(
                `SSR: user with handle '${ctx.query.handle}' redirected to KB article for Save and Protect users.`
              );
              break;
            case 'REDIRECT_SKILLS':
              // eslint-disable-next-line no-case-declarations
              const targetUrl = psUsername
                ? `${process.env.PLURALSIGHT_SKILLS_PROFILE_BASE_URL}${psUsername}`
                : `${process.env.PLURALSIGHT_SKILLS_PROFILE_BASE_URL}`;
              if (isUserAnonymousVisitor) {
                // redirect to the target
                ctx.res.writeHead(301, {
                  Location: targetUrl
                });
              } else {
                // redirect to the target but ask to login
                const encodedTargetUrl = encodeURIComponent(targetUrl);
                ctx.res.writeHead(301, {
                  Location: `${process.env.PLURALSIGHT_OIDC_URL}RedirectTo=${encodedTargetUrl}&${process.env.PLURALSIGHT_OIDC_ALIAS}`
                });
              }
              ctx.res.end();
              console.log(
                `SSR: user with handle '${ctx.query.handle}' redirected to PS Skills Profile '${psUsername}', isUserAnonymousVisitor: ${isUserAnonymousVisitor}`
              );
              break;
            case 'REGULAR_404':
            case 'BLOCKLIST_404':
            default:
              ctx.res.statusCode = 404;
              console.log('SSR: user redirected to 404 error page');
              return { isProfileNotFound: true };
          }
        }
      } catch (error) {
        console.error('SSR: failed to getDataFromTree', error);
        bugsnagClient.notify(error, {
          context: 'SSR using Apollo.getDataFromTree',
          request: {
            url: ctx.req.url
          }
        });

        if (!process.env.CLOUD_PROFILE_EXPERIENCE_ACTIVE) {
          ctx.res.statusCode = 404;
          console.log('SSR: user redirected to 404 error page');
          return { isProfileNotFound: true };
        }
      }
    }

    const apolloState = apolloClient.extract();
    console.log(
      `SSR: size of data in the client cache: ${
        JSON.stringify({ apolloState }).length
      }`
    );
    return {
      ...pageProps,
      apolloState
    };
  };

  return WithApollo;
};

export { getWithApollo };
