import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';

import { getConfig } from '../../config/get-config';

const {
  DEFAULT_REDIRECT_URI,
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID
} = getConfig();

const authService = makeAuthService({
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID,
  DEFAULT_REDIRECT_URI
});

export { authService };
