// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  ConfigProvider as RainbowConfigProvider,
  learnTheme
} from '@a-cloud-guru/rainbow-ui';

import './index.less';

import { ErrorBoundary } from '../src/clients/bugsnag';
import { Tracker } from '../src/components/shared/tracking/Tracker';

const { breakpoints } = learnTheme;

const ProfileApp = ({ pageProps, Component, router }) => {
  return (
    <RainbowConfigProvider theme={learnTheme}>
      <style global jsx>
        {`
          body: {
            min-width: ${breakpoints.xxs};
          }
        `}
      </style>
      <script src="//cdn.split.io/sdk/split-10.13.0.min.js"></script>
      <script src="https://cdn.jsdelivr.net/npm/@a-cloud-guru/wc-footer@0.0.6/dist/wc-footer.umd.js"></script>
      <script
        src="https://cdn.jsdelivr.net/npm/@a-cloud-guru/wc-app-switcher@1.1.0/dist/wc-app-switcher.umd.js"
        defer
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/@a-cloud-guru/wc-app-switcher-banner@0.0.6/dist/wc-app-switcher-banner.umd.js"
        defer
      ></script>
      <ErrorBoundary>
        <Tracker>
          <Component {...router} {...pageProps} />
        </Tracker>
      </ErrorBoundary>
    </RainbowConfigProvider>
  );
};

ProfileApp.propTypes = {
  pageProps: PropTypes.any,
  Component: PropTypes.any,
  router: PropTypes.any,
  apollo: PropTypes.object
};

export default ProfileApp;
