import { gql } from '@apollo/client';

import { apolloClient } from '../../clients/apollo/standAloneClient';

export const TRACK_PAGE_LOAD = gql`
  mutation page($page: PageInput) {
    page(event: $page)
  }
`;

export const TRACK_PAGE_EVENT = gql`
  mutation trackEvent($event: EventInput) {
    trackEvent(event: $event)
  }
`;

export const IDENTIFY_MUTATION = gql`
  mutation identify($event: IdentifyInput) {
    identify(event: $event)
  }
`;

export const trackPageLoad = pageLoad => {
  if (pageLoad) {
    apolloClient
      .mutate({
        variables: {
          page: {
            ...pageLoad
          }
        },
        mutation: TRACK_PAGE_LOAD
      })
      .catch(error => console.log(error));
  }
};

export const trackPageEvent = pageEvent => {
  if (pageEvent) {
    apolloClient
      .mutate({
        variables: {
          event: {
            ...pageEvent
          }
        },
        mutation: TRACK_PAGE_EVENT
      })
      .catch(error => console.log(error));
  }
};

export const track = (eventType, properties) => {
  trackPageEvent({ eventType, properties: JSON.stringify(properties) });
};

export const identify = async () =>
  apolloClient
    .mutate({
      mutation: IDENTIFY_MUTATION,
      variables: { event: { traitProperties: '{}' } }
    })
    .catch(err => console.error(err));
