import { trackPageLoad, trackPageEvent } from './trackingService';
import { findElement } from './eventFilter';
import { getEventName } from './eventDetails';

export const onPageLoad = getPageDetails => {
  const pageDetails = getPageDetails();

  if (!pageDetails) {
    return;
  }

  const { applicationName, pageName, pathname } = pageDetails;

  trackPageLoad({
    name: getEventName({
      applicationName,
      pageName,
      actionPerformed: 'Viewed'
    }),
    category: '',
    properties: `{ "path": "${pathname}" }`
  });
};

export const onPageEvent = (e, trackedElements, getPageDetails) => {
  const elementName = findElement(
    e,
    ['target.classList', 'target.parentElement.classList'],
    trackedElements
  );
  if (!elementName) {
    return;
  }

  const { applicationName, pageName, pathname } = getPageDetails();

  if (e.type === 'click') {
    trackPageEvent({
      eventType: getEventName({
        applicationName,
        pageName,
        actionPerformed: 'Clicked',
        elementName
      }),
      properties: `{ "path": "${pathname}" }`
    });
  }
};
