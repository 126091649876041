import { get, map, find, intersection, first } from 'lodash';

export const findElement = (event, pathsToSearch, trackedElements) => {
  const classLists = map(pathsToSearch, path => get(event, path, []));

  const matchingClassList =
    find(
      classLists,
      classList => intersection(classList, trackedElements).length > 0
    ) || [];

  return getElementNameFromClassname(
    first(intersection(matchingClassList, trackedElements)) || null
  );
};

const getElementNameFromClassname = classname => {
  if (!classname) {
    return null;
  }

  return classname
    .split('-')
    .map(capitaliseWordFirstLetter)
    .join(' ');
};

const capitaliseWordFirstLetter = word => {
  return word || word.length > 0
    ? word[0].toUpperCase() + word.substring(1)
    : '';
};
