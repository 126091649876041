module.exports = {
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_TEAMS_DOMAIN: 'https://staging-teams.acloud.guru',
  ACG_SCHOOL_DOMAIN: 'https://staging-learn.acloud.guru',
  ACG_INTERNAL_PROFILE_DOMAIN: 'https://dev-profile.acloud.guru',
  ACG_MEMBERSHIP_DOMAIN: 'https://staging-checkout.acloud.guru/signup',
  ACG_VERIFY_DOMAIN: 'https://staging-verify.acloud.guru',
  ALGOLIA_PUBLIC_SEARCH_KEY: '244df520f0ab19af76ed4ae40ce9a1ef',
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  ALGOLIA_COMPANY_NAME_INDEX_NAME: 'prod_companies',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_ROLES_NAMESPACE: 'https://ns.acloud.guru/roles',
  BUGSNAG_API_KEY: 'be470a55cee15da1796290e0fc6827f3',
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  DEFAULT_REDIRECT_URI:
    'https://dev-profile.acloud.guru/profile/login/callback',
  GOOGLE_PLACES_API_KEY: 'AIzaSyD7BObL5wzGvxnBmDhdMDMLR4w7-N_mgY4',
  GRAPHQL_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql/',
  PENDO_API_KEY: 'a006fa43-8a84-427f-4cf3-6e601ba0fe76',
  PLACES_APP_ID: 'plGT9D0FCXMK',
  PLACES_SEARCH_KEY: '019704cf8bdd2a89da623287e53b6751',
  PROFILE_NO_IMAGE: 'https://public.acloud.guru/assets/profile-no-image.png',
  SPLIT_IO_API_KEY: '23jn0mnpvput87lcs5ns2vekp796tgh4ibvu',
  SSR_ENABLED: true,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '5a7ca26c0826d72e1c7c9fb4',
  PLURALSIGHT_SKILLS_PROFILE_BASE_URL:
    'https://app-stage.pluralsight.com/profile/',
  SUNSET_PROFILE_REDIRECTION: true,
  CLOUD_PROFILE_EXPERIENCE_ACTIVE: true,
  PLURALSIGHT_OIDC_URL: 'https://app-stage.pluralsight.com/id/signin/sso?',
  PLURALSIGHT_OIDC_ALIAS: 'Alias=acg-test',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
  ALLOWED_DOMAINS: [
    'http://127.0.0.1',
    'http://localhost',
    'https://*.acloud.guru',
    'https://brave-saha-7b070c.netlify.com',
    'https://*.auth0.com',
    'https://acg',
    'https://pocket-school.netlify.app',
    'https://*.linuxacademy.com',
    'https://*.pluralsight.com'
  ]
};
