import { isNil, join, pipe, reject } from 'ramda';

const Sections = {
  Certificate: 'Industry Certifications',
  Role: 'Employment History'
};

const getSectionName = elementName => {
  const [action, cluePart1, cluePart2] = elementName.split(' ');
  const clue = cluePart2 ? `${cluePart1} ${cluePart2}` : cluePart1;
  return `${Sections[clue] ? Sections[clue] : clue} - ${action}`;
};

const formatEventNameForSection = pipe(reject(isNil), join(' - '));

export const getEventName = eventDetails => {
  if (!eventDetails) {
    throw new Error('Missing tracked event details');
  }

  const {
    applicationName,
    actionPerformed,
    pageName,
    elementName
  } = eventDetails;
  const section = elementName ? getSectionName(elementName) : null;

  return formatEventNameForSection([
    applicationName,
    section || pageName,
    actionPerformed
  ]);
};
