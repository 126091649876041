import { attachAuth, createApolloClient } from 'hocs/apollo';

const result = attachAuth()();

const apolloClient = createApolloClient({
  initialState: {},
  headers: result.headers
});

export { apolloClient };
